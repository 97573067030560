import React, { useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';
import profilePic from '../images/profilePic.jpeg'; // Ensure the path to your image is correct


function AboutMeImage() {
    const controls = useAnimation();
    const aboutMeRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    controls.start({ opacity: 1, transition: { duration: 3 } });
                    observer.unobserve(entry.target); // Stop observing after animation
                }
            },
            { threshold: 0.5 }
        );

        if (aboutMeRef.current) {
            observer.observe(aboutMeRef.current);
        }

        return () => {
            if (aboutMeRef.current) {
                observer.unobserve(aboutMeRef.current);
            }
        };
    }, [controls]);

    return (
        <motion.div
            ref={aboutMeRef}
            className="mx-6 flex flex-col lg:flex-row items-center lg:items-start lg:gap-10"
            initial={{ opacity: 0 }}
            animate={controls}
        >
            {/* Profile Image */}
            <div className="w-48 h-48 lg:w-52 lg:h-52 rounded-full overflow-hidden shadow-lg flex-shrink-0 mx-10">
                <img
                    src={profilePic}
                    alt="Profile"
                    className="w-full h-full object-cover"
                />
            </div>

            {/* About Text */}
            <div className="mt-5 lg:mt-0 text-center lg:text-left ">
                <div className="text-4xl md:text-5xl font-semibold text-white leading-tight">
                    Hi, I'm Matthew. A Creator.
                </div>
                <div className="mt-5 text-xl md:text-2xl font-semibold text-white xl:w-10/12 md:mr-8">
                I am currently researching deep learning methods for HIV clinical decision-making tools at the Lee Lab and exploring reliable human-AI interaction in vision-language models at the GLAMOR Lab. Previously, I interned at the Valero Lab, where I applied machine learning to EEG data to classify harmful brain diseases.
                <br /> <br/>I am also passionate about graphics, art, and creating interactive experiences.


                </div>
            </div>
        </motion.div>
    );
}



export default AboutMeImage;
